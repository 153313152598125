<template>
  <div class="wx-pay">
    <div class="w-container" v-loading="loading">
      <div class="w-hd">
        <div class="h-title">微信支付</div>
        <div class="h-money">
          应付金额:
          <strong>{{ amount }}</strong>
          元
        </div>
      </div>
      <div class="w-bd">
        <div class="b-qr">
          <div class="q-img">
            <vue-qr :text="cimgs" :size="310"></vue-qr>
          </div>
          <div class="q-txt">
            <div class="q-icon">
              <i class="iconfont icon-saoyisao"></i>
            </div>
            <div class="q-content">
              <li>请使用微信扫一扫</li>
              <li>扫描二维码支付</li>
            </div>
          </div>
        </div>
        <div class="b-img">
          <img src="../../assets/images/phone.png" />
        </div>
      </div>
      <div class="w-ft">
        <a :href="'/home/payment?id=' + id">
          <i class="fa fa-angle-left fa-lg"></i>
          &nbsp;
          <span>选择其他支付方式</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import VueQr from 'vue-qr'
import { wxPay,PayStatus } from '@/api/payment'
export default {
   components: { VueQr },
  name:"wxPay",
  data(){
    return{
      id:0,
      amount:0,
      cimgs:"/img/wx-qr.png",
      timeName:null,
    }
  },
  mounted(){
    this.timeName = setInterval(() => {
      setTimeout(this.wxSuccess(), 0)
    }, 2000)
  },
  beforeDestroy(){
    clearInterval(this.timeName);
  },
  methods: {
    wxSuccess(){ //  实时查询是否支付
      PayStatus(this.id).then(res => {
        if(res.data.message=="ok"){
          clearInterval(this.timeName);
          this.$message("订单支付成功");
          this.$router.push('/user/orderList');
        }
      });
    },
    async QRcode(){ // 去后台请求二维码地址
      this.loading = true;
      const {data: res} = await wxPay(this.id);
      if(res.message=='ok'){
       this.cimgs  = res.result.imgurl;
       this.amount = res.result.amount;
      }else{
        this.$router.push('/home/error?mes=服务器繁忙，请稍后在试');
        return false;
      }
      this.loading = false;
    },
  },
  created() {
    this.id  = this.$route.query.id;
    if(typeof this.id !== 'number' && isNaN(this.id)){ this.$router.push('/home/error?mes=服务器繁忙，请稍后在试');return false;}
    this.QRcode();
  }
}

</script>

<style lang="less" scoped>
.wx-pay {
  background: #f9f9f9;
  padding: 40px 0px;

  .w-container {
    margin: 0px auto;
    width: 1200px;
    // min-height: 618px;
    // border: 1px solid #e1251b;
    background: #fff;
    padding: 40px;
    .w-hd {
      display: flex;
      justify-content: space-between;
      .h-title {
        font-size: 20px;
        font-weight: 600;
      }
      .h-money {
        font-size: 14px;
        color: #666;
        strong {
          color: #e1251b;
          font-size: 22px;
          font-weight: 700;
        }
      }
    }
    .w-bd {
      //   height: 400px;
      display: flex;
      justify-content: center;
      margin: 40px 0px;
      box-sizing: border-box;
      //   border: 1px solid #e1251b;
      .b-qr {
        .q-img {
          width: 308px;
          height: 308px;
          border: 2px solid #ccc;
          box-sizing: border-box;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .q-txt {
          width: 308px;
          height: 72px;
          //   border: 1px solid #e1251b;
          margin-top: 20px;
          background: #e1251b;
          display: flex;
          justify-content: center;
          align-items: center;
          i {
            font-size: 40px;
            color: #fff;
          }
        }
        .q-content {
          margin-left: 40px;
          font-size: 14px;
          color: #fff;
        }
      }
      .b-img {
        width: 262px;
        height: 400px;
        // border: 1px solid #e1251b;
        margin-left: 150px;
        img {
          width: 262px;
          height: 400px;
        }
      }
    }
    .w-ft {
      font-size: 16px;
      a {
        color: #236fe9;
      }
      a:hover {
        color: #e1251b;
      }
    }
  }
}
</style>