import request from '@/libs/request'

/**
 * 支付方式选择页订单详情
 */
export function PaymentType(id) {
    return request({
        url: `/payment/paymenttype/id/${id}`,
        method: 'get'
    })
}

/**
 * 微信支付二维请求地址
 */
export function wxPay(id) {
    return request({
        url: `/payment/wxpay/id/${id}`,
        method: 'get'
    })
}

/**
 * 微信支付二维请求地址
 */
export function PayStatus(id) {
    return request({
        url: `/payment/paystatus/id/${id}`,
        method: 'get'
    })
}

/**
 * 支付宝网站支付表单请求地址
 */
export function aliPayPage(payid) {
  return request({
    url: `/payment/alipay/${payid}`,
    method: 'get'
  })
}
